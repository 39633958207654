
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue, prop } from "vue-class-component";
import CryptoJS from "crypto-js";
import { AccountController } from "../../controllers/AccountController";
import { QueryParamsEntities2 } from "@/domain/entities/MainApp";

class Props {
  modelValue = prop<boolean>({
    type: Boolean
  });
}

@Options({
  emits: ["update:modelValue"]
})
export default class PPOBPopupModal extends Vue.with(Props) {
  props = this.$props;
  closeDialog() {
    this.$emit("update:modelValue", false);
  }
  forceCloseDialog() {
    this.$emit("update:modelValue", false);
  }
  get posID() {
    return AccountController.accountData.account_type_detail.id;
  }
  get dateTime() {
    return Math.round(new Date().getTime() / 1000);
  }

  get EncryptPosID() {
    const key: string = process.env.VUE_APP_AES_KEY || "";
    const iv: string = process.env.VUE_APP_AES_IV || "";
    const data = this.posID.toString();

    const cipher = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });

    return cipher.toString();
  }

  get urlPPOB(): string {
    const base = process.env.VUE_APP_URL_PPOB;
    if (base) {
      const params = {
        partner_id: process.env.VUE_APP_PPOB_PARTNER_ID,
        user_id: this.EncryptPosID
      };
      return `${base}/#/?${new QueryParamsEntities2(params).queryString}`;
    }
    return "";
  }
}
